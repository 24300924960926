import { environment } from "../../environments/environment";

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Appointment } from "../models/appointment";

const apiUrl = `${environment.backend}/v1/appointments`;

@Injectable({
    providedIn: "root"
})
export class AppointmentsService {
    constructor(private http: HttpClient) {}

    getAppointmentByRoomId(id: string): Promise<Appointment> {
        return this.http
            .get<Appointment>(
                `${apiUrl}/room/${id}`
            )
            .toPromise();
    }
}
