import {
    Component,
    ViewChild,
    ElementRef,
    AfterViewInit,
    NgZone,
    ChangeDetectorRef,
    OnDestroy
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CallService } from "../../services/call.service";

declare var ScaleDrone: any;

@Component({
    selector: "app-call",
    templateUrl: "./call.component.html",
    styleUrls: ["./call.component.scss"]
})
export class CallComponent implements AfterViewInit, OnDestroy {
    @ViewChild("localVideo", { static: false }) localVideo: ElementRef;
    @ViewChild("remoteVideo", { static: false }) remoteVideo: ElementRef;

    pc: RTCPeerConnection;
    configuration: RTCConfiguration;
    drone: any;
    room: any;
    roomName: string;
    mediaStream: MediaStream;
    connected: boolean = false;
    roomHash: string;

    sendChannel: RTCDataChannel;
    receiveChannel: RTCDataChannel;

    audioMute: boolean = false;
    videoMute: boolean = false;

    remoteVideoMute: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private callService: CallService
    ) {}

    async ngAfterViewInit() {
        this.roomHash = this.route.snapshot.params.connectionCode;

        this.mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            },
            video: {
                aspectRatio: 4 / 3,
                facingMode: 'user'
            }
        });

        // TODO: Replace with your own channel ID
        this.drone = new ScaleDrone("37nBkemedi06JCT1", {
            data: {
                type: "patient"
            }
        });
        // Room name needs to be prefixed with 'observable-'
        this.roomName = "observable-" + this.roomHash;
        
        const twilioIceServers = await this.callService
            .requestTurnToken()
            .then((token: any) => token.iceServers);

        this.configuration = {
            iceServers: twilioIceServers
        };

        this.drone.on("open", error => {
            if (error) {
                return console.error(error);
            }
            this.room = this.drone.subscribe(this.roomName);
            this.room.on("open", error => {
                if (error) {
                    this.onError(error);
                }
                console.log("room joined");
            });
            // We're connected to the room and received an array of 'members'
            // connected to the room (including us). Signaling server is ready.
            this.room.on("members", (members: Array<any>) => {
                console.log("MEMBERS", members);
                if (
                    members.filter(
                        member => member.clientData.type === "patient"
                    ).length > 1
                )
                    this.router.navigateByUrl("/");
                // If we are not the first user to connect to the room we will be creating the offer
                const isOfferer =
                    members.filter(
                        member => member.clientData.type === "doctor"
                    ).length >= 1;
                this.startWebRTC(isOfferer);
            });
        });

        this.localVideo.nativeElement.muted = true;
        this.localVideo.nativeElement.volume = 0;
    }

    onSuccess() {}

    async startWebRTC(isOfferer) {
        this.pc = new RTCPeerConnection(this.configuration);

        // 'onicecandidate' notifies us whenever an ICE agent needs to deliver a
        // message to the other peer through the signaling server
        this.pc.onicecandidate = event => {
            console.log(event.candidate);
            if (event.candidate) {
                this.sendDroneMessage({ candidate: event.candidate });
            }
        };

        // If user is offerer let the 'negotiationneeded' event create the offer
        if (isOfferer) {
            console.log("isOfferer");
            this.pc.onnegotiationneeded = () => {
                console.log("negotiate");
                this.pc
                    .createOffer()
                    .then(desc => this.localDescCreated(desc))
                    .catch(res => this.onError(res));
            };
        }

        // When a remote stream arrives display it in the #remoteVideo element
        this.pc.ontrack = event => {
            this.connected = true;
            const stream = event.streams[0];
            if (
                !this.remoteVideo.nativeElement.srcObject ||
                this.remoteVideo.nativeElement.srcObject.id !== stream.id
            ) {
                this.remoteVideo.nativeElement.srcObject = stream;
            }
        };

        // Display your local video in #localVideo element
        this.localVideo.nativeElement.srcObject = this.mediaStream;
        this.localVideo.nativeElement.muted = true;
        this.localVideo.nativeElement.volume = 0;
        
        // Add your stream to be sent to the conneting peer
        this.mediaStream
            .getTracks()
            .forEach(track => this.pc.addTrack(track, this.mediaStream));

        this.sendChannel = this.pc.createDataChannel("sendChannel");

        this.pc.ondatachannel = event => {
            this.receiveChannel = event.channel;
            this.receiveChannel.onmessage = event =>
                this.handleReceiveMessage(event);
            this.receiveChannel.onopen = state =>
                this.handleReceiveChannelStatusChange(state);
            this.receiveChannel.onclose = state =>
                this.handleReceiveChannelStatusChange(state);
        };

        // Listen to signaling data from Scaledrone
        this.room.on("data", (message, client) => {
            // Message was sent by us
            if (client.id === this.drone.clientId) {
                return;
            }

            if (message.sdp) {
                // This is called after receiving an offer or answer from another peer
                this.pc
                    .setRemoteDescription(
                        new RTCSessionDescription(message.sdp)
                    )
                    .then(() => {
                        // When receiving an offer lets answer it
                        if (this.pc.remoteDescription.type === "offer") {
                            this.pc
                                .createAnswer()
                                .then(desc => this.localDescCreated(desc))
                                .catch(res => this.onError(res));
                        }
                    })
                    .catch(res => this.onError(res));
            } else if (message.candidate) {
                // Add the new ICE candidate to our connections remote description
                console.log(message.candidate);
                this.pc
                    .addIceCandidate(new RTCIceCandidate(message.candidate))
                    .then(() => this.onSuccess())
                    .catch(res => this.onError(res));
            }
        });
    }

    handleReceiveMessage(event) {
        console.log(`Event: ${event.data}`);
        if (event.data === "videoMute") {
            this.remoteVideoMute = true;
            this.cd.detectChanges();
        } else if (event.data === "videoUnmute") {
            this.remoteVideoMute = false;
            this.cd.detectChanges();
        } else if (event.data === "endCall") {
            this.endCall();
        }
    }

    handleReceiveChannelStatusChange(state) {}

    sendDataMessage(message) {
        this.sendChannel.send(message);
    }

    sendDroneMessage(message) {
        this.drone.publish({
            room: this.roomName,
            message
        });
    }

    localDescCreated(desc) {
        this.pc
            .setLocalDescription(desc)
            .then(() =>
                this.sendDroneMessage({ sdp: this.pc.localDescription })
            )
            .catch(this.onError);
    }

    onError(error) {
        console.error(error);
    }

    toggleAudioMute() {
        this.audioMute = !this.audioMute;
        this.mediaStream.getAudioTracks()[0].enabled = !this.mediaStream.getAudioTracks()[0]
            .enabled;
        this.sendDataMessage(this.audioMute ? "audioMute" : "audioUnmute");
    }

    toggleVideoMute() {
        this.videoMute = !this.videoMute;
        this.mediaStream.getVideoTracks()[0].enabled = !this.mediaStream.getVideoTracks()[0]
            .enabled;
        this.sendDataMessage(this.videoMute ? "videoMute" : "videoUnmute");
    }

    endCall() {
        try {
            this.sendDataMessage("endCall");
            this.pc.close();
        } catch (e) {}
        this.drone.close();
        this.mediaStream.getTracks().forEach(track => track.stop());
        this.ngZone.run(() => this.router.navigateByUrl(`/completed`));
    }

    ngOnDestroy() {
        this.drone.close();
        this.mediaStream.getTracks().forEach(track => track.stop());
    }
}
