import * as Moment from "moment";
import { timer, Subscription } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentsService } from "src/app/services/appointments.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../dialog/dialog.component";

@Component({
    selector: "app-startcall",
    templateUrl: "./startcall.component.html",
    styleUrls: ["./startcall.component.scss"]
})
export class StartCallComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appointmentsService: AppointmentsService,
        private errorDialog: MatDialog
    ) {}

    timeToAppointment: Moment.Duration;
    timeToAppointmentString: string;
    appointmentTime: Moment.Moment;
    isBefore: boolean;
    canJoin: boolean;
    connectionCode: string;

    updateTimer: Subscription;

    updateTime() {
        this.timeToAppointment = Moment.duration(
            this.appointmentTime.diff(Moment())
        );

        const openingTime = Moment(this.appointmentTime).subtract(
            15,
            "minutes"
        );

        this.isBefore = Moment().isBefore(this.appointmentTime);
        this.canJoin = Moment().isAfter(openingTime);
        this.timeToAppointmentString = this.timeToAppointment.humanize();
    }

    async ngOnInit() {
        this.connectionCode = this.route.snapshot.params.connectionCode;

        try {
            const appt = await this.appointmentsService.getAppointmentByRoomId(
                this.connectionCode
            );
            this.appointmentTime = Moment(appt.time);
        } catch (error) {
            const dialogRef = this.errorDialog.open(DialogComponent, {
                width: "250px",
                data: {
                    heading: "Oops!",
                    message: "It looks like that appointment doesn't exist!"
                }
            });
            const subscription = dialogRef.afterClosed().subscribe(() => {
                subscription.unsubscribe();
                this.router.navigateByUrl("/");
            });
            return;
        }

        this.updateTimer = timer(0, 1000).subscribe(v => {
            this.updateTime();
        });
    }

    ngOnDestroy() {
        try {
            this.updateTimer.unsubscribe();
        } catch (e) {}
    }

    connect() {
        this.router.navigateByUrl(`/call/${this.connectionCode}`);
    }
}
