import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { CallComponent } from "./components/call/call.component";
import { CompletedComponent } from "./components/completed/completed.component";
import { StartCallComponent } from "./components/startcall/startcall.component";

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "completed", component: CompletedComponent },
    { path: ":connectionCode", component: StartCallComponent },
    { path: "call/:connectionCode", component: CallComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
