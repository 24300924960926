import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule
} from "@angular/material";
import { HomeComponent } from "./components/home/home.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { CallComponent } from "./components/call/call.component";
import { MaterialElevationDirective } from "./styling/material-elevation.directive";
import { CompletedComponent } from './components/completed/completed.component';
import { StartCallComponent } from './components/startcall/startcall.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        DialogComponent,
        CallComponent,
        MaterialElevationDirective,
        CompletedComponent,
        StartCallComponent
    ],
    entryComponents: [DialogComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
